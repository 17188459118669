.form-login{
    max-width: 650px;
}

.btn-cadastro{
    background-color: #222;
    color:#fff;
}
.btn-cadastro:hover{
    background-color: #d31717;
    color:#fff;
}
