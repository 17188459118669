.navbar{
    background-color: #07090c;
	
	padding-top: 30px;
}

.nav-item a{
    color: #fff;
    transition: .3s ease-in;
}

.nav-item a:hover{
    color: #d31717;
}

.nav-item {
	border-left:1px solid #222;
}

.nav-item:last-child {
	border-right:1px solid #222;
}

.nav-item {
	padding: 10px 15px;
	color: #FFF;
	text-transform: uppercase;
}


.nav-item:hover, .main-nav li a:focus {
	background-color:#222;
}

