.login-content{
    width: 100%;
    padding: 15%;
    margin: auto;
    background-color: #07090c;;
    height: 100vh;
}
.btn.btn-login{
    background-color: rgb(61, 55, 55);
    color: #fff;;
    transition: .3s ease-in;
}
.btn.btn-login:hover{
    background-color: #d31717;
    color: #fff;

}
.opcoes-login a{
    text-decoration: none;
    color: #fff;
    transition: .3s ease-in;
}
.opcoes-login a:hover{
    color: #d31717;
}